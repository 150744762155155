<template>
  <v-container fluid class="pa-0">
    <v-row class="link-expired-container ma-0">
      <v-col cols="12" md="9" sm="8" class="link-expired">
        <div class="image-container">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="280.000000pt"
            height="280.000000pt"
            viewBox="0 0 280.000000 280.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,280.000000) scale(0.100000,-0.100000)"
              :fill="mainColor"
              stroke="none"
            >
              <path
                d="M883 2786 c-58 -26 -63 -51 -63 -287 l0 -211 34 -34 c40 -40 92 -46
143 -14 48 29 55 66 51 292 l-3 198 -30 30 c-37 36 -87 46 -132 26z"
              />
              <path
                d="M2135 2794 c-192 -40 -228 -64 -512 -348 l-243 -242 0 -53 c0 -46 4
-57 31 -82 26 -24 40 -29 82 -29 l51 0 240 238 c196 194 251 243 292 260 76
32 148 37 224 18 109 -28 183 -90 234 -196 27 -56 31 -76 30 -145 -1 -146 2
-142 -457 -603 -221 -222 -420 -413 -442 -426 -124 -72 -297 -53 -407 43 -66
57 -127 60 -178 9 -25 -25 -30 -37 -30 -81 0 -48 2 -53 53 -100 62 -58 189
-121 271 -136 135 -24 251 -9 376 51 84 40 89 45 501 457 234 234 433 440 453
471 61 94 88 188 89 310 1 82 -3 120 -20 175 -64 203 -232 357 -438 400 -66
14 -156 18 -200 9z"
              />
              <path
                d="M316 2560 c-60 -18 -93 -79 -75 -142 12 -46 381 -414 426 -427 79
-23 143 27 143 112 l0 51 -197 198 c-109 109 -207 201 -218 204 -41 11 -51 12
-79 4z"
              />
              <path
                d="M54 1961 c-37 -22 -59 -77 -49 -120 3 -15 20 -41 36 -57 l30 -29 204
-3 c230 -4 262 3 290 59 23 44 14 104 -19 138 l-26 26 -218 3 c-200 2 -219 1
-248 -17z"
              />
              <path
                d="M1170 1820 c-71 -9 -119 -25 -205 -67 -63 -32 -106 -71 -451 -416
-216 -216 -398 -406 -418 -438 -149 -228 -117 -530 78 -725 181 -182 440 -223
676 -108 66 33 99 61 282 242 214 211 236 242 223 301 -7 32 -49 75 -83 86
-56 17 -81 -2 -287 -206 -213 -211 -254 -239 -357 -254 -74 -11 -171 11 -233
54 -109 73 -160 170 -159 301 0 139 3 143 427 568 421 422 422 423 561 430
100 5 167 -17 252 -84 42 -32 67 -44 91 -44 50 0 92 26 110 67 23 56 10 93
-51 151 -114 108 -293 164 -456 142z"
              />
              <path
                d="M2283 1036 c-67 -30 -82 -131 -29 -185 l26 -26 215 -3 c243 -4 265 1
292 65 20 48 10 92 -28 129 l-30 29 -207 2 c-161 2 -214 0 -239 -11z"
              />
              <path
                d="M2063 809 c-48 -14 -73 -52 -73 -112 l0 -51 197 -198 c210 -211 231
-225 298 -207 47 13 75 55 75 115 l0 48 -197 198 c-109 109 -207 201 -218 204
-41 11 -51 12 -82 3z"
              />
              <path
                d="M1804 561 c-51 -31 -55 -56 -52 -284 l3 -206 29 -30 c37 -38 81 -48
129 -28 61 26 67 49 67 277 0 222 -5 247 -60 275 -39 20 -78 19 -116 -4z"
              />
            </g>
          </svg>
        </div>
        <h3 class="message">{{ $t("Link is no longer available") }}</h3>
        <div v-if="cardData" class="go-home-btn-container">
          <button
            class="go-home-btn ff-bold"
            :style="{ background: mainColor }"
            @click="redirect"
          >
            {{ $t("Back") }}
          </button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Common from "@/utils/common";
export default {
  name: "link-expired",
  props: ["cardData"],
  data: () => ({
    mainColor: null,
  }),
  mounted() {},
  methods: {
    redirect() {
      Common.handleRedirection(this.cardData);
    },
  },
};
</script>

<style lang="scss">
@import "./link-expired.scss";
</style>
