<template>
  <div>
    <v-date-picker
      class="calender-size"
      :weekday-format="getDay"
      v-model="picker"
      full-width
      first-day-of-week="-1"
      :min="new Date().toISOString()"
      color="primary"
      ref="date-picker-ref"
      @input="updateDate"
      :locale="$store.state.bookingSteps.stepsData.lang"
    ></v-date-picker>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "date-picker",

  components: {},
  props: ["availableDates"],

  data: () => ({
    picker: null,
  }),

  mounted() {
    this.picker = new Date().toISOString().slice(0, 10);
    this.updateDate();
  },

  methods: {
    getDay(date) {
      const daysOfWeek = [
        this.$t("weekDaysText.Sunday"),
        this.$t("weekDaysText.Monday"),
        this.$t("weekDaysText.Tuesday"),
        this.$t("weekDaysText.Wednesday"),
        this.$t("weekDaysText.Thursday"),
        this.$t("weekDaysText.Friday"),
        this.$t("weekDaysText.Saturday"),
      ];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    getAllowedDates(val) {
      let currentData = new Date().toISOString();
      console.log(moment().diff(val, "days"));
      console.log(val);
      return moment(val).isAfter(currentData);
    },
    updateDate() {
      this.$emit("input", this.picker);
    },
  },
};
</script>
<style lang="scss">
.booking-steps {
  @media (min-width: 960px) {
    .v-picker.v-card.v-picker--date.v-picker--full-width.theme--light {
      height: 440px;
    }
  }
  .v-picker.v-card.v-picker--date.v-picker--full-width.theme--light {
    height: 240px;
  }
  .v-date-picker-table table th {
    color: var(--main-color) !important;
  }
  .v-date-picker-table--date .v-btn {
    color: var(--secondary-color);
  }
  .v-date-picker-table--date .v-btn--active {
    color: white;
  }
  .v-date-picker-header .v-date-picker-header__value {
    flex: none !important;
    order: 1 !important;
  }
  .v-date-picker-table table td button div {
    letter-spacing: 0 !important;
    line-height: 0 !important;
    margin-top: 4px;
  }

  @media screen and (max-width: 800px) {
    .v-date-picker-table table th {
      font-size: 8px !important;
    }

    .v-card > *:last-child:not(.v-btn):not(.v-chip) {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      top: -20px;
      padding-bottom: 4px;
    }
  }
}
</style>
