<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" class="mt-2 pb-0">
        <div>
          <div class="card-listing-header">
            <div class="img-container">
              <img :src="logo" alt="logo"/>
            </div>
            <div class="header-text">
              <h3>{{ header }}</h3>

              <p class="desc">{{ convertToArabicNumbers(description) }}</p>
              <div class="branches">
                {{ this.order.branch && this.order.branch.name }}
              </div>
            </div>
          </div>
          <div class="px-1 px-md-6 ff-regular dark-gray-font">
            <div class="ff-bold">{{ $t('Delivering to') }}</div>
            <div @click="dialog = true" class="cursor-pointer">
              {{ convertToArabicNumbers(selectedAddress.desc) }}
              <v-icon :color="this.mainColor">mdi-chevron-down</v-icon>
            </div>
            <div class="overlay-container-correction">
              <v-overlay :value="dialog" class="d-flex justify-center">
                <div class="overlay-container pa-5">
                  <div class="close-btn-container mb-1 px-1">
                    <v-icon :color="mainColor" @click="dialog = false"
                    >mdi-close
                    </v-icon
                    >
                  </div>
                  <div class="list-address">
                    <h3 class="ff-Bold font-xxl dark-gray-font px-2">
                      {{ $t('Delivering Address') }}
                    </h3>
                    <v-radio-group
                        class="mt-0"
                        v-model="selectedAddress"
                        @change="selectAddress"
                        column
                    >
                      <v-radio
                          v-for="(address, index) in addresses"
                          :key="index"
                          :value="address"
                          class="mt-5 mb-4"
                      >
                        <template slot="label">
                          <div class="address-text px-2">
                            <div class="address-title mb-1">
                              {{ convertToArabicNumbers(address.title) }}
                            </div>
                            <div class="address-desc">{{ convertToArabicNumbers(address.desc) }}</div>
                            <div class="addresses-divider-container mt-2 mb-0">
                              <v-divider class="addresses-divider"></v-divider>
                            </div>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <span
                        text
                        class="add-new-address px-1"
                        depressed
                        @click="
                        $router.push({
                          path: '/order',
                          query: {
                            user_id: $route.query.user_id,
                            bot_id: $route.query.bot_id,
                          },
                        })
                      "
                    >
                      <v-icon class="mb-1"> mdi-plus </v-icon>
                      <span class="text-decoration-underline">
                        {{ $t('Add New Address') }}
                      </span>
                    </span>
                  </div>
                </div>
              </v-overlay>
            </div>
          </div>
          <div class="expansion-container">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, i) in meals" :key="i">
                <v-expansion-panel-header class="item-title">{{
                    convertToArabicNumbers(item.name)
                  }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(card, ind) in item.meals.data" :key="ind">
                    <meal-card :card="card"/>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </v-col>
    </v-row>
    <meal-dialog-details/>
  </v-container>
</template>
<script>
import axios from 'axios';
import MealCard from './meal-card.vue';
import MealDialogDetails from './meal-dialog-details.vue';
import {Formatter} from 'sarala-json-api-data-formatter';
import shared from "@/views/ordering/shared";

const formatter = new Formatter();

export default {
  components: {MealCard, MealDialogDetails},
  created() {
    this.getItemSelectedOptions = shared.getItemSelectedOptions
  },
  data() {
    return {
      selectedAddress: {},
      dialog: false,
      addresses: [],
    };
  },
  mounted() {
    this.listAddresses();
  },
  methods: {
    convertToArabicNumbers: function(data){
      if(this.$i18n.locale !== 'ar'){
        return data;
      }else{
        const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
        return String(data).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]});
      }
    },
    selectAddress(v) {
      this.updateOrder(v.id);
      this.address = {
        address_id: v.id,
        title: v.title,
        desc: v.desc,
        lat: v.lat,
        lon: v.lon,
      };
      this.mapAddress = {lat: v.lat, lon: v.lon};
      this.dialog = false;
    },

    updateOrder(__id) {
      this.calculating = true;

      let toBeSentOrder = shared.createUpdatedOrder(this.order, __id, this.notes, this.basket);
      axios
          .put(
              `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/orders/customer/update/${this.order_id}?include=address,discount,meals,meals.mealsAttributeSets`,
              toBeSentOrder
          )
          .then((res) => {
            let data = JSON.parse(
                JSON.stringify(formatter.deserialize(res.data))
            );
            this.paymentDetails = data.cost;
            this.order = {...data};
          })
          .catch((e) => {
            this.overlay.error = true;
            this.overlay.error_msg = e.response.data.message;
          })
          .finally(() => {
            this.calculating = false;
          });
    },

    listAddresses() {
      axios
          .get(
              `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/addresses?order_id=${this.order_id}`
          )
          .then((res) => {
            let data = JSON.parse(
                JSON.stringify(formatter.deserialize(res.data))
            );
            let arr = [];
            data.data.map((add) => {
              arr.push({
                id: add.id,
                desc: add.address,
                lat: add.location.latitude,
                lon: add.location.longitude,
              });
            });
            this.addresses = [...arr];
          })
          .then(() => {
            this.selectedAddress = {
              id: this.order.address.data.id,
              desc: this.order.address.data.address,
            };
            this.address = {
              address_id: this.order.address.data.id,
            };
          });
    },
  },

  computed: {
    overlay: {
      get: function () {
        return this.$store.state.order_ext.overlay;
      },
      set: function (value) {
        this.$store.commit('updateOverlay', value);
      },
    },
    mapAddress: {
      get() {
        return this.$store.state.order_map.mapAddress;
      },
      set(value) {
        this.$store.dispatch('setMapAddress', value);
      },
    },
    calculating: {
      get() {
        return this.$store.state.order_ext.calculating;
      },
      set(value) {
        this.$store.commit('updateCalculating', value);
      },
    },
    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit('updateBasket', value);
      },
    },

    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit('updatePaymentDetails', value);
      },
    },
    address: {
      get() {
        return this.$store.state.order_map.address;
      },
      set(value) {
        this.$store.dispatch('setAddress', value);
      },
    },
    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit('updateOrderId', value);
      },
    },
    meals: {
      get() {
        return JSON.parse(JSON.stringify(this.$store.state.order_ext.meals));
      },
      set(value) {
        this.$store.commit('updateMeals', value);
      },
    },
    header: {
      get: function () {
        return this.$store.state.order_ext.header;
      },
      set: function (value) {
        this.$store.commit('updateHeader', value);
      },
    },
    description: {
      get: function () {
        return this.$store.state.order_ext.description;
      },
      set: function (value) {
        this.$store.commit('updateDescription', value);
      },
    },
    logo: {
      get: function () {
        return this.$store.state.order_ext.logo;
      },
      set: function (value) {
        this.$store.commit('updateLogo', value);
      },
    },
    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
  },
};
</script>
<style lang="scss">
@import '../../styles/card-listing.scss';

.overlay-container-correction {
  .v-overlay__content {
    display: flex;
    justify-content: center;
    width: 32%;
  }

  .overlay-container {
    background: white;
    color: black;
    max-height: 65vh;
    overflow-y: scroll;

    .close-btn-container {
      display: flex;
      justify-content: flex-end;
    }

    .list-address {
      height: auto !important;
      padding: 0;
      overflow: auto;
      background: white;
      padding-bottom: 0;

      .v-radio {
        .v-icon {
          color: var(--main-color) !important;
        }
      }

      .addresses-divider-container {
        position: relative;

        .addresses-divider {
          position: absolute;
          left: -15px;
          border-color: #d4d3d3;
          width: calc(100% + 20px);
        }
      }
    }
  }
}

.ar {
  .address-title,
  .address-text {
    text-align: right;
  }

  .addresses-divider-container {
    position: relative;

    .addresses-divider {
      position: absolute;
      left: 15px !important;
      border-color: #d4d3d3;
      width: calc(100% + 20px);
    }
  }
}

@media screen and (max-width: 960px) {
  .overlay-container-correction {
    .v-overlay__content {
      width: 80%;
    }
  }
}

@media screen and (max-width: 600px) {
  .overlay-container-correction {
    .v-overlay__content {
      position: fixed !important;
      bottom: 0 !important;
      width: 100% !important;
      /* padding-bottom: 100px !important ; */
      .overlay-container {
        padding-bottom: 100px !important;
        width: 100% !important;
      }
    }
  }
}
</style>
