<template>
  <v-container fluid>
    <div>
      <div class="ocr-steps" :style="clientStyle">
        <div class="logo d-flex justify-space-between">
          <img :src="$store.state.core.clientStyle.logo" alt="" />
          <div>
            <h3 v-if="title">{{ $t(title) }}</h3>
            <p class="grey--text" style="font-size: 13px">
              {{ description }}
            </p>
          </div>
        </div>

        <link-expired v-if="expired" :cardData="cardData" />

        <v-stepper
          v-if="currentStep"
          v-model="currentStep.id"
          alt-labels
          elevation="0"
          v-ripple="false"
        >
          <v-stepper-header>
            <template v-for="(s, index) in cardData.steps">
              <v-stepper-step
                :key="`${index}-step`"
                :complete="s.id != currentStep.id"
                :step="s.id"
                :editable="false"
              >
                <p
                  class="mb-0 text-center d-block"
                  v-if="s.name == 'national-id'"
                >
                  {{ $t("NationalId") }}
                </p>
                <p
                  class="mb-0 text-center d-block"
                  v-if="s.name == 'car-license'"
                >
                  {{ $t("CarLicense") }}
                </p>
              </v-stepper-step>

              <v-divider :key="s.id"></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <step1
                @nextStep="setCurrentStep"
                @submitData="submitData"
                @cancel="cancelDialog"
              ></step1>
            </v-stepper-content>

            <v-stepper-content step="2">
              <step2
                @nextStep="setCurrentStep"
                @submitData="submitData"
                @cancel="cancelDialog"
              ></step2>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </v-container>
</template>

<script>
import Common from "@/utils/common";
import Loader from "../../common/loader.vue";
import LinkExpired from "../../errors/link-expired";
import step1 from "./step1/step1.vue";
import step2 from "./step2/step2.vue";
export default {
  name: "ocr",
  components: {
    step1,
    step2,
    Loader,
    LinkExpired,
  },

  data: () => ({
    cardData: null,
    expired: false,
  }),
  async mounted() {
    await this.$store.dispatch("setLoader", true);
    await this.setStyle();
    await this.getCardData();
    // this.expired = true;
    await this.$store.dispatch("setLoader", false);
  },
  methods: {
    async getCardData() {
      try {
        const res = await this.$http_gateway.get(
          `extended-message?user_id=${this.$route.query.user_id}&bot_id=${this.$route.query.bot_id}&slug=ocr`
        );

        if (Object.keys(res.data).length && !res.data.not_found) {
          this.cardData = res.data;

          await this.$store.dispatch("setOcrStepsData", this.cardData);
          await this.$store.dispatch("setLang", this.cardData.lang);
          await this.$store.dispatch("setCurrentStep", this.cardData.steps[0]);
          // console.log(this.cardData.lang);
          this.$vuetify.rtl = this.cardData.lang === "ar";
          this.$i18n.locale = this.cardData.lang;
          await this.setStyle();
        } else {
          this.expired = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async setStyle() {
      await this.$store.dispatch("setClientStyle", {
        botId: this.$route.query.bot_id,
        lang: this.$i18n.locale,
      });
      this.$vuetify.theme.themes.light.primary = this.$store.state.core.clientStyle.mainColor;
      this.$vuetify.theme.themes.light.secondary = this.$store.state.core.clientStyle.introButtonsColor;
      document.documentElement.style.setProperty(
        "--main-color",
        this.$store.state.core.clientStyle.mainColor
      );
    },
    async submitData() {
      await this.$store.dispatch("setLoader", true);
      try {
        const rest = await this.$http_gateway.post(`extended-message`, {
          channel: this.$store.state.ocrSteps.stepsData.channel,
          user_id: this.$route.query.user_id,
          bot_id: this.$route.query.bot_id,
          text: JSON.stringify(this.$store.state.ocrSteps.uploadedStepsData),
        });
        Common.handleRedirection(this.cardData);
      } catch (e) {
        await this.$store.dispatch("setLoader", false);

        console.log(e);
      } finally {
        await this.$store.dispatch("setLoader", false);
      }
    },
    async cancelDialog() {
      await this.$store.dispatch("setLoader", true);

      try {
        const res = await this.$http_gateway.post(`/cancel_all_dialogs`, {
          channel: this.$store.state.ocrSteps.stepsData.channel,
          userId: this.$route.query.user_id,
          botId: this.$route.query.bot_id,
          cancelRedirectBlock: this.$store.state.ocrSteps.stepsData
            .cancelRedirectBlock,
          slug: "ocr",
        });
        Common.handleRedirection(this.cardData);
      } catch (e) {
        console.log(`cancel dialog error`, e);
      } finally {
        await this.$store.dispatch("setLoader", false);
      }
    },
    setCurrentStep(step) {
      this.$store.dispatch("setCurrentStep", step);
    },
  },
  computed: {
    clientStyle() {
      return {
        "--main-color": this.$store.state.core.clientStyle.mainColor,
        "--secondary-color": this.$store.state.core.clientStyle
          .introButtonsColor,
      };
    },

    title() {
      let title = this.$store.state.ocrSteps.stepsData.extensionTitle;
      if (title) {
        return title.toLowerCase();
      }
      return null;
    },
    description() {
      let description = this.$store.state.ocrSteps.stepsData
        .extensionDescription;
      if (description) {
        return description;
      }
      return null;
    },
    currentStep() {
      return this.$store.state.ocrSteps.currentStep;
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
.v-stepper__header {
  height: 100px;
  margin-top: -16px;
}

::v-deep .v-stepper__header .v-ripple__container {
  display: none !important;
}

.ocr-steps {
  position: relative;
  .logo {
    margin-top: 12px;
    width: 100%;

    img {
      height: 40px;
    }
  }
}

@media (min-width: 1400px) {
  .ocr-steps {
    .logo {
      margin: auto;
      z-index: 1000;
      max-width: 300px;
      top: 10px;
      left: 75px;
    }
  }
  .v-application--is-rtl {
    .ocr-steps {
      .logo {
        right: 750px;
        left: auto;
      }
    }
  }
}

.v-application--is-rtl {
  .ocr-steps {
    .logo {
      right: 75px;
      left: auto;
    }
  }
}
</style>
