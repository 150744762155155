<template>
  <div class="added-order">
    <v-divider class="my-2" v-if="!orderSummary"></v-divider>
    <div class="title-container">
      <h3>
        <div class="count">
          <div>{{ convertToArabicNumbers(count) }}</div>
          <div>X</div>
        </div>
        <div class="title-direct-container">
          {{ title }}
          <span v-for="(el) in getOptionsText()" class="span-with-options">
            {{ convertToArabicNumbers(el.value.length ? " | " + el.text + " - " + el.value + " " : "") }}
          </span>
        </div>
      </h3>
      <div
          class="delete-container"
          @click="() => removeItem(id)"
          v-if="!orderSummary">
        <v-icon> mdi-trash-can</v-icon>
      </div>
    </div>
    <div class="desc d-flex justify-space-between">
      <p>{{ desc }}<span v-if="orderSummary&&comment"> - {{ comment }}</span></p>
      <div class="price-container" v-if="orderSummary">
        <h3>{{ convertToArabicNumbers(getPrice()) }} {{ currency }}</h3>
      </div>
    </div>

    <template v-if="!orderSummary">
      <div class="price-container">
        <h3>{{ convertToArabicNumbers(getPrice()) }} {{ currency }}</h3>
        <div class="icons-container">
          <v-btn
              class="mx-0"
              dark
              color="var(--main-color)"
              :disabled="count == 1 || this.calculating"
              @click="() => minusOne(id)">
            <v-icon dark> mdi-minus</v-icon>
          </v-btn>
          <div class="count">{{ convertToArabicNumbers(count) }}</div>
          <v-btn
              class="mx-0"
              dark
              :disabled="this.calculating"
              color="var(--main-color)"
              @click="() => plusOne(id)">
            <v-icon dark> mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="addCommentParent">
        <span>{{ $t("add comment") }}</span>
      </div>
      <div class="comment-container">
        <v-text-field
            outlined
            color="light-gray"
            name="input-7-4"
            :value="comment"
            @input="inputHandler"
            @blur="blurEffect"
            dense
            hide-details
            :placeholder="$t('comment_placeholder')"
        ></v-text-field>
      </div>
    </template>
  </div>
</template>

<script>
import shared from "@/views/ordering/shared";
import axios from "axios";
import {Formatter} from 'sarala-json-api-data-formatter';

const formatter = new Formatter();

export default {
  props: [
    "title",
    "options",
    "index",
    "desc",
    "price",
    "id",
    "count",
    "currency",
    "item",
    "comment",
    "orderSummary",
  ],
  data: () => ({
    localComment: null,
  }),
  mounted() {
    this.getOptionsText();
    this.getPrice();
  },
  methods: {
    convertToArabicNumbers: function (data) {
      if (this.$i18n.locale !== 'ar') {
        return data;
      } else {
        const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
        return String(data).replace(/[0123456789]/g, (d) => {
          return arabicNumbers[d]
        });
      }
    },
    getPrice() {
      let price = 0;
      for (let i = 0; i < this.options.length; ++i) {
        let selected_options = this.options[i];
        if (Array.isArray(selected_options.selected)) {
          for (let j = 0; j < selected_options.selected.length; ++j) {
            let selected = selected_options.selected[j];
            for (
                let k = 0;
                k < selected_options.mealsAttributeSetOption.data.length;
                ++k
            ) {
              let selected_meals =
                  selected_options.mealsAttributeSetOption.data[k];
              //console.log("seleced id", selected)
              if (selected_meals.id === selected) {
                price += selected_meals.price;
              }
            }
          }
        }
        else if(selected_options.selected){
          for (
              let k = 0;
              k < selected_options.mealsAttributeSetOption.data.length;
              ++k
          ) {
            let selected_meals =
                selected_options.mealsAttributeSetOption.data[k];
            if (selected_meals.id === selected_options.selected) {
              //console.log("seleced id", selected_options.selected)
              price += selected_meals.price;
            }
          }
        }
      }
      if (this.options.length === 0) {
        price += parseFloat(this.price);
      }
      return price*this.item.quantity;
    },
    getOptionsText() {
      let arr = [];
      for (let i = 0; i < this.options.length; ++i) {
        let selected_options = this.options[i];
        if (Array.isArray(selected_options.selected)) {
          for (let j = 0; j < selected_options.selected.length; ++j) {
            let selected = selected_options.selected[j];
            for (
                let k = 0;
                k < selected_options.mealsAttributeSetOption.data.length;
                ++k
            ) {
              let selected_meals =
                  selected_options.mealsAttributeSetOption.data[k];
              //console.log("seleced id", selected)

              if (selected_meals.id === selected) {
                arr.push({
                  text: selected_options.title,
                  value: selected_meals.title,
                });
              }
            }
          }
        } else {
          for (
              let k = 0;
              k < selected_options.mealsAttributeSetOption.data.length;
              ++k
          ) {
            let selected_meals =
                selected_options.mealsAttributeSetOption.data[k];
            if (selected_meals.id === selected_options.selected) {
              ///console.log("seleced id", selected_options.selected)
              arr.push({
                text: selected_options.title,
                value: selected_meals.title,
              });
            }
          }
        }
      }
      return arr;

      /*let arr = this.options.map((el) => {
        return {
          text: el.title,
          value: el.mealsAttributeSetOption.data.map((e) => e.title),
        };
      });
      return arr;*/
    },
    inputHandler: function (e) {
      this.localComment = e;
    },
    blurEffect: function () {
      let arr = this.newBasket.map((e) => {
        if (e.id === this.id){
          e.mealsAttributeSets = e.mealsAttributeSets ? e.mealsAttributeSets : e.mealAttributeSets.data;
          if(shared.combineSelected(this.options) === shared.combineSelected(e.mealsAttributeSets)){
            e.comment = this.localComment;
          }
        }
        return e;
      });
      this.newBasket = arr;
      this.updateOrder(this.newBasket);
    },
    plusOne: function () {
      for (let index = 0; index < this.newBasket.length; index++) {
        if (index === this.index) this.newBasket[index].quantity++;
      }
      this.updateOrder(this.newBasket);

    },
    minusOne: function () {
      for (let index = 0; index < this.newBasket.length; index++) {
        const element = this.newBasket[index];
        if (index === this.index && element.quantity > 1)
          this.newBasket[index].quantity--;
      }
      this.updateOrder(this.newBasket);
    },
    removeItem: function () {
      for (let index = 0; index < this.newBasket.length; index++) {
        if (index === this.index) this.newBasket.splice(index, 1);
      }
      this.updateOrder(this.newBasket);
    },
    updateOrder: function (arr) {
      this.calculating = true;

      let toBeSentOrder = shared.createUpdatedOrder(this.order, this.address.address_id, this.notes, arr);
      ///console.log("toBeSentOrder items", toBeSentOrder.items);

      axios.put(`${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/orders/customer/update/${this.order_id}?include=address,discount,meals,meals.mealsAttributeSets`, toBeSentOrder)
          .then((res) => {
            let data = JSON.parse(
                JSON.stringify(formatter.deserialize(res.data))
            );
            this.paymentDetails = data.cost;
            this.order = {...data};
            this.basket = [...arr];
          })
          .catch((e) => {
            this.overlay.error = true;
            this.overlay.error_msg = e.response.data.message;
          })
          .finally(() => {
            this.calculating = false;
          });
    }
  },
  computed: {
    overlay: {
      get: function () {
        return this.$store.state.order_ext.overlay;
      },
      set: function (value) {
        this.$store.commit("updateOverlay", value);
      },
    },
    meals: {
      get() {
        return this.$store.state.order_ext.meals;
      },
      set(value) {
        this.$store.commit("updateMeals", value);
      },
    },
    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit("updateOrderId", value);
      },
    },
    address: {
      get() {
        return this.$store.state.order_map.address;
      },
      set(value) {
        this.$store.dispatch("setAddress", value);
      },
    },
    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit("updateBasket", value);
      },
    },
    newBasket: {
      get: function () {
        return JSON.parse(JSON.stringify(this.$store.state.order_ext.basket));
      },
    },
    notes: {
      get() {
        return this.$store.state.order_ext.notes;
      },
      set(value) {
        this.$store.commit("updateNotes", value);
      },
    },
    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit("updatePaymentDetails", value);
      },
    },
    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit("updateOrder", value);
      },
    },
    calculating: {
      get() {
        return this.$store.state.order_ext.calculating;
      },
      set(value) {
        this.$store.commit("updateCalculating", value);
      },
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/added-order.scss";
</style>
