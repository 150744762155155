<template>
  <div class="allowed-times">
    <!--    <p-->
    <!--      class="secondary&#45;&#45;text text-center"-->
    <!--      v-if="!selectedDate"-->
    <!--      style="margin-top: 65px">-->
    <!--      {{ $t('No appointments available') }}-->
    <!--    </p>-->
    <!--    <p-->
    <!--      v-if="selectedDate && !availableSlots.length"-->
    <!--      class="text-center"-->
    <!--      style="margin-top: 0px">-->
    <!--      {{ $t('No appointments available') }}-->
    <!--    </p>-->

    <v-item-group
      v-model="selectedTime"
      class="slots-button d-flex flex-wrap justify-space-around mb-6"
      @change="changeTime"
    >
      <v-item
        v-for="(time, index) in availableSlots"
        :key="index"
        v-slot="{ active, toggle }"
      >
        <v-btn
          @click="toggle"
          :disabled="!time.available"
          :color="active ? 'primary' : 'secondary'"
          :dark="!!active"
          outlined
          large
          class="mb-6"
          :value="time"
          >{{ time.time }}</v-btn
        >
      </v-item>
    </v-item-group>
  </div>
</template>

<script>
export default {
  name: "allowedTimes",
  props: {
    availableSlots: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedDate: {
      type: String,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      selectedTime: null,
    };
  },
  methods: {
    changeTime() {
      this.$emit("changeTime", this.availableSlots[this.selectedTime].time);
    },
  },
};
</script>

<style scoped lang="scss">
.slots-button {
  top: 0px;
  .theme--dark.v-btn {
    color: white !important;
  }
  .v-btn {
    width: 100px;
  }
}

.allowed-times {
  margin-top: 30px;
  padding-bottom: 100px;
}

@media (min-width: 960px) {
  .slots-button {
    margin-top: 12px;
    padding: 0 10px;
    text-align: center;
    .v-btn {
      width: 30%;
    }
  }
  .booking-steps .allowed-times {
    padding-bottom: 100px;
  }
}
</style>

<style>
.v-date-picker-table table td button div {
  letter-spacing: 0 !important;
  line-height: 0 !important;
}
</style>
