import Vue from "vue";
import axios from "axios";

let apiGateway = process.env.VUE_APP_API_GATEWAY;
let uploadsBaseUrl = process.env.VUE_APP_UPLOADS_URL;
let apiSessionManager = process.env.VUE_APP_API_SESSION_MANAGER;

Vue.prototype.$http_gateway = axios.create({
  baseURL: apiGateway,
});

Vue.prototype.$http_session = axios.create({
  baseURL: apiSessionManager,
});

Vue.prototype.$http_uploads = axios.create({
  baseURL: uploadsBaseUrl,
  headers: {
    "Content-Type": "multipart/form-data; boundary=MultipartBoundry",
  },
});
