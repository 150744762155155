<template>
  <div class="meal-card" :style="`opacity: ${!card.in_stock ? '.5' : '1'}`">
    <div class="img-container">
      <img :src="card.image" alt="meal" />
    </div>
    <div class="card-desc">
      <div class="title-price-container">
        <h3 class="card-name">
          {{ card.name }}
        </h3>
        <h3
          class="card-price"
          :style="{ 'text-align': $i18n.locale == 'ar' ? 'left' : 'right' }"
        >
          {{ convertToArabicNumbers(card.price) }} {{ card.currency }}
        </h3>
      </div>
      <p class="description">{{ convertToArabicNumbers(card.description) }}</p>
      <p v-if="!card.in_stock" class="out-of-stock ff-bold">
        {{ $t('out of stock') }}
      </p>
      <div class="icon-container">
        <!-- <v-btn
          dark
          :disabled="!isInBasket || !card.in_stock || this.calculating"
          color="var(--main-color)"
          @click="() => minusOne(card)"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
        <div
          class="mx-3"
          style="
            color: var(--main-color);
            font-family: Tag-Bold;
            font-size: 18px;
          "
        >
          {{ getCardAmount(card.id) }}
        </div> -->
        <v-btn
          class="mx-0"
          dark
          :disabled="!card.in_stock || this.calculating"
          color="var(--main-color)"
          @click="() => addToBasket(card)"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Formatter } from 'sarala-json-api-data-formatter';
import shared from "@/views/ordering/shared";
const formatter = new Formatter();
export default {
  props: ['card'],
  data: function () {
    return {
      isInBasket: false,
    };
  },
  methods: {
    getCardAmount(id) {
      let member = this.basket.find((e) => {
        if (
          (e.id.indexOf('-') > -1 ? e.id.split('-')[1] : e.id) ===
          (id.indexOf('-') > -1 ? id.split('-')[1] : id)
        )
          return e;
      });
      if (member?.quantity) return member?.quantity;
      else return 0;
    },
    addToBasket: function (card) {
      this.overlayMealDetails = {
        ...{
          dialog: true,
          showErr: false,
          doUpdate: false,
          data: {
            ...card,
            mealsAttributeSets: card.mealsAttributeSets.data,
            quantity: 1,
          },
        },
      };
    },
   convertToArabicNumbers: function(data){
      if(this.$i18n.locale !== 'ar'){
        return data;
      }else{
        const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
        return String(data).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]});
      }
    },

    updateOrder: function (arr) {
      this.calculating = true;
      let toBeSentOrder = shared.createUpdatedOrder(this.order, this.address.address_id, this.notes, arr);
      console.log("toBeSentOrder items", toBeSentOrder.items);
      axios
        .put(
          `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/orders/customer/update/${this.order_id}?include=address,discount,meals,meals.mealsAttributeSets`,
          toBeSentOrder
        )
        .then((res) => {
          let data = JSON.parse(
            JSON.stringify(formatter.deserialize(res.data))
          );
          this.paymentDetails = data.cost;
          this.order = { ...data };
          this.basket = [...arr];
        })
        .catch((e) => {
          this.overlay.error = true;
          this.overlay.error_msg = e.response.data.message;
        })
        .finally(() => {
          this.calculating = false;
        });
    }

  },
  mounted() {
  },

  watch: {
    overlayMealDetails(newValue){
      if(newValue.doUpdate){
        newValue.doUpdate = false;
        let newBasket = JSON.parse(JSON.stringify(this.basket));
        const found = newBasket.some((el) =>
                (el.id.indexOf('-') > -1 ? el.id.split('-')[1] : el.id) ===
                (newValue.data.id.indexOf('-') > -1 ? newValue.data.id.split('-')[1] : newValue.data.id));
        //same id not found
        if (!found){
          newBasket.push({ ...newValue.data, quantity: newValue.data.quantity, comment: newValue.data.comment, order: newBasket.length });
        }
        else{
          //same id is found, let's check if it has same options
          var foundWithSameOptions = false;
          let newItemID = newValue.data.id.indexOf('-') > -1 ? newValue.data.id.split('-')[1] : newValue.data.id
          for (let i = 0; i < newBasket.length; ++i) {
            newBasket[i].mealsAttributeSets = newBasket[i].mealsAttributeSets ? newBasket[i].mealsAttributeSets : newBasket[i].mealAttributeSets.data;
            let iID = newBasket[i].id.indexOf('-') > -1 ? newBasket[i].id.split('-')[1] : newBasket[i].id
            if(shared.combineSelected(newBasket[i].mealsAttributeSets) === shared.combineSelected(newValue.data.mealsAttributeSets)
                && iID === newItemID){
              newBasket[i].quantity = newBasket[i].quantity + newValue.data.quantity;
              newBasket[i].comment = newValue.data.comment;
              foundWithSameOptions = true;
            }
          }
          //same id is found, but not with same options
          if(!foundWithSameOptions){
            newBasket.push({ ...newValue.data, quantity: newValue.data.quantity, comment: newValue.data.comment,  order: newBasket.length});
          }
        }

        this.updateOrder(newBasket);
      }
    }
  },
  computed: {
    overlayMealDetails: {
      get() {
        return this.$store.state.order_ext.overlayMealDetails;
      },
      set(value) {
        this.$store.commit('updateOverlayMealDetails', value);
      },
    },
    overlay: {
      get: function () {
        return this.$store.state.order_ext.overlay;
      },
      set: function (value) {
        this.$store.commit('updateOverlay', value);
      },
    },
    meals: {
      get() {
        return this.$store.state.order_ext.meals;
      },
      set(value) {
        this.$store.commit('updateMeals', value);
      },
    },
    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit('updateOrderId', value);
      },
    },
    address: {
      get() {
        return this.$store.state.order_map.address;
      },
      set(value) {
        this.$store.dispatch('setAddress', value);
      },
    },
    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit('updateBasket', value);
      },
    },
    notes: {
      get() {
        return this.$store.state.order_ext.notes;
      },
      set(value) {
        this.$store.commit('updateNotes', value);
      },
    },

    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit('updatePaymentDetails', value);
      },
    },
    calculating: {
      get() {
        return this.$store.state.order_ext.calculating;
      },
      set(value) {
        this.$store.commit('updateCalculating', value);
      },
    },

    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/meal-card.scss';
</style>
