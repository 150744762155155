<template>
  <div class="add-address-manually px-6 pt-12 pb-6">
    <div :class="`back-arrow-container d-block d-md-none ${$i18n.locale}`">
      <v-icon
          :color="mainColor"
          size="25"
          class="cursor-pointer"
          @click="updatePageStatus(false)"
      >
        mdi-arrow-left
      </v-icon
      >
    </div>
    <h3 class="title ff-bold">{{ $t('Add new address') }}</h3>
    <p class="ff-regular">{{ $t('Please complete the following') }}</p>

    <v-form ref="form" class="add-address-form" v-model="valid" lazy-validation>
      <div
          class="building-details d-flex justify-space-between align-center mb-1"
      >
        <div>
          <label for="" class="hide-extra ff-regular">{{
              $t('District Name')
            }}</label>
          <v-text-field
              class="ff-regular"
              :value="district"
              :rules="[
              function (v) {
                return !!v || $t('This field is required');
              },
            ]"
              v-model="district"
              dense
              outlined
              :placeholder="$t('District Name')"
          ></v-text-field>
        </div>

        <div>
          <label for="" class="ff-regular">{{ $t('Street Name') }}</label>
          <v-text-field
              class="ff-regular"
              :value="street"
              :rules="[
            function (v) {
              return !!v || $t('This field is required');
            },
            function (v) {
              return (
                v.search(/[!@#$%^&*_+=\[\]{};:\\|<>?~]/i) === -1 ||
                $t('address_validation_msg')
              );
            },
          ]"
              v-model="street"
              dense
              outlined
              :placeholder="$t('Street Name')"
          ></v-text-field>
        </div>

      </div>
      <div
          class="building-details d-flex justify-space-between align-center mb-1"
      >
        <div>
          <label for="" class="hide-extra ff-regular">{{
              $t('Bulding Number')
            }}</label>
          <v-text-field
              class="ff-regular"
              :value="building"
              :rules="[
              function (v) {
                return !!v || $t('This field is required');
              },
            ]"
              v-model="building"
              dense
              outlined
              :placeholder="$t('Bulding Number')"
          ></v-text-field>
        </div>
        <div>
          <label class="hide-extra ff-regular" for="">{{
              $t('Floor Number')
            }}</label>
          <v-text-field
              class="ff-regular"
              :value="floor"
              :rules="[
              function (v) {
                return !!v || $t('This field is required');
              },
            ]"
              v-model="floor"
              dense
              outlined
              :placeholder="$t('Floor Number')"
          ></v-text-field>
        </div>
      </div>
      <div class="building-details d-flex justify-space-between mb-1">
        <div>
          <label class="hide-extra ff-regular" for="">{{
              $t('Apartment Number')
            }}</label>
          <v-text-field
              class="ff-regular"
              :value="apartment"
              :rules="[
              function (v) {
                return !!v || $t('This field is required');
              },
            ]"
              v-model="apartment"
              dense
              outlined
              :placeholder="$t('Apartment Number')"
          ></v-text-field>
        </div>
        <div>
          <label class="hide-extra ff-regular">{{
              $t('Address Type')
            }}</label>
          <v-select
              :menu-props="menuClass"
              class="ff-regular add-address-select"
              :value="type"
              v-model="type"
              :items="items"
              item-text="text"
              item-value="id"
              :placeholder="$t('Choose')"
              dense
              outlined
          ></v-select>
        </div>
      </div>
      <div class="mb-2">
        <label class="ff-regular">{{ $t('Special Sign') }}</label>
        <div class="mt-2">
          <textarea
              class="mod-textarea ff-regular"
              v-model="notes"
              rows="3"
              :placeholder="$t('Special Sign')"
          ></textarea>
          <span>{{ $t('address_disclaimer') }}</span>
        </div>
      </div>
    </v-form>

    <div class="btn-container d-flex justify-center">
      <v-btn
          id="checkout"
          depressed
          dark
          :color="mainColor"
          @click="confirmAddress"
      >{{ $t('save_address') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {Formatter} from 'sarala-json-api-data-formatter';

const formatter = new Formatter();
import axios from 'axios';

export default {
  data: function () {
    return {
      menuClass: {
        contentClass:
            localStorage.getItem('locale') == 'ar' && 'ar-select-with-direction',
      },
      type: '',
      street: '',
      building: '',
      district: '',
      apartment: '',
      floor: '',
      notes: '',

      items: [
        {
          id: 'home',
          text: this.$t('home'),
        },
        {
          id: 'work',
          text: this.$t('work'),
        },
      ],
      valid: true,
    };
  },
  mounted() {
    const address = this.$store.state.order_map.address;
    this.type = address.type;
    this.street = address.street;
    this.building = address.building;
    this.apartment = address.apartment;
    this.floor = address.floor;
    this.notes = address.notes;
    this.district = address.district;
  },

  methods: {
    resetAddress() {
      this.address = {
        address_id: null,
        type: '',
        street: '',
        building: '',
        apartment: '',
        district: '',
        floor: '',
        notes: '',
      };
      this.map_address = {
        lat: '',
        lon: '',
      };
      this.pageStatus = {...this.pageStatus, showForm: false};
    },

    confirmAddress() {
      if (this.$refs.form.validate()) {
        const addressData = {
          type: this.type,
          street: this.street,
          building: this.building,
          apartment: this.apartment,
          floor: this.floor,
          notes: this.notes,
          district: this.district
        };

        this.$store.dispatch('setAddress', addressData);

        let dataForNonExisted = {
          order_id: this.order_id,
          address_id: null,
          address: this.street,
          latitude: this.map_address.lat,
          longitude: this.map_address.lon,
          building_number: this.building,
          floor_number: this.floor,
          apartment_number: this.apartment,
          address_type: this.type,
          note: this.notes,
          district: this.district
        };
        axios({
          method: 'post',
          url: `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/addresses?include=discount,meals.mealsAttributeSets`,
          headers: {},
          data: dataForNonExisted,
        }).then((res) => {
          let data = formatter.deserialize(
              JSON.parse(JSON.stringify(res.data))
          );
          //console.log('confirm manual address', data);
          this.discountCode = data.discount ? data.discount.data : {};

          this.basket = data.meals.data;
          this.paymentDetails = data.cost;
          this.order = this.updateOrderToHaveSelectedIfAny(data);
          this.notes = data.notes || '';
          this.meals = data.menu.data;
          this.address = {...this.address, address_id: data.address.data.id};

          this.$router.push({
            path: '/order/ext',
            query: {
              user_id: this.$route.query.user_id,
              bot_id: this.$route.query.bot_id,
            },
          });
        });
      }
    },
    updateOrderToHaveSelectedIfAny(order) {
      let mealsArr = order.meals.data;
      for (let i = 0; i < mealsArr.length; ++i) {
        let meal = mealsArr[i];
        for (let j = 0; j < meal.mealAttributeSets.data.length; ++j) {
          meal.mealAttributeSets.data[j].selected = [];
          meal.mealAttributeSets.data[j].selectedPrice = 0;
          let options = meal.mealAttributeSets.data[j].mealsAttributeSetOption.data;
          for (let k = 0; k < options.length; ++k) {
            console.log("selectedOption", options);
            meal.mealAttributeSets.data[j].selected.push(options[k].id);
            meal.mealAttributeSets.data[j].selectedPrice += options[k].price;
          }
        }
        mealsArr[i] = meal;
      }
      order.meals.data = mealsArr;
      return order;
    },
    updatePageStatus(value) {
      this.pageStatus.showForm = value;
      this.pageStatus = {...this.pageStatus};
    },
  },

  computed: {
    discountCode: {
      get() {
        return this.$store.state.order_map.mapAddress;
      },
      set(value) {
        this.$store.commit('updateDiscountCode', value);
      },
    },
    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit('updatePaymentDetails', value);
      },
    },

    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit('updateBasket', value);
      },
    },
    meals: {
      get() {
        return this.$store.state.order_ext.meals;
      },
      set(value) {
        this.$store.commit('updateMeals', value);
      },
    },
    map_address: {
      get: function () {
        return this.$store.state.order_map.mapAddress;
      },
      set: function (value) {
        this.$store.commit('setMapAddress', value);
      },
    },
    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit('updateOrderId', value);
      },
    },
    pageStatus: {
      get: function () {
        return this.$store.state.order_map.pageStatus;
      },
      set: function (value) {
        this.$store.dispatch('setPageStatus', value);
      },
    },

    address: {
      get: function () {
        return this.$store.state.order_map.address;
      },
      set: function (value) {
        this.$store.dispatch('setAddress', value);
      },
    },
    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
  },
  watch: {
    address() {
      const address = this.$store.state.order_map.address;
      this.type = address.type;
      this.street = address.street;
      this.building = address.building;
      this.apartment = address.apartment;
      this.floor = address.floor;
      this.notes = address.notes;
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/add-location-manually.scss';
</style>
