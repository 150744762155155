<template>
  <div>
    <v-style
      v-if="
        $store.state.core.clientStyle.font &&
        !$store.state.core.clientStyle.customFont
      "
    >
      @import url('https://fonts.googleapis.com/css?family={{
        $store.state.core.clientStyle.font
      }}');
    </v-style>
    <v-style>
      .v-application { font-family: {{ $store.state.core.clientStyle.font }},
      sans-serif !important; }
    </v-style>
  </div>
</template>

<script>
export default {
  name: "dynamicStyle",
  async mounted() {},
};
</script>

<style scoped></style>
