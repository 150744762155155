var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-address-manually px-6 pt-12 pb-6"},[_c('div',{class:("back-arrow-container d-block d-md-none " + (_vm.$i18n.locale))},[_c('v-icon',{staticClass:"cursor-pointer",attrs:{"color":_vm.mainColor,"size":"25"},on:{"click":function($event){return _vm.updatePageStatus(false)}}},[_vm._v(" mdi-arrow-left ")])],1),_c('h3',{staticClass:"title ff-bold"},[_vm._v(_vm._s(_vm.$t('Add new address')))]),_c('p',{staticClass:"ff-regular"},[_vm._v(_vm._s(_vm.$t('Please complete the following')))]),_c('v-form',{ref:"form",staticClass:"add-address-form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"building-details d-flex justify-space-between align-center mb-1"},[_c('div',[_c('label',{staticClass:"hide-extra ff-regular",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('District Name')))]),_c('v-text-field',{staticClass:"ff-regular",attrs:{"value":_vm.district,"rules":[
            function (v) {
              return !!v || _vm.$t('This field is required');
            } ],"dense":"","outlined":"","placeholder":_vm.$t('District Name')},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}})],1),_c('div',[_c('label',{staticClass:"ff-regular",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Street Name')))]),_c('v-text-field',{staticClass:"ff-regular",attrs:{"value":_vm.street,"rules":[
          function (v) {
            return !!v || _vm.$t('This field is required');
          },
          function (v) {
            return (
              v.search(/[!@#$%^&*_+=\[\]{};:\\|<>?~]/i) === -1 ||
              _vm.$t('address_validation_msg')
            );
          } ],"dense":"","outlined":"","placeholder":_vm.$t('Street Name')},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})],1)]),_c('div',{staticClass:"building-details d-flex justify-space-between align-center mb-1"},[_c('div',[_c('label',{staticClass:"hide-extra ff-regular",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Bulding Number')))]),_c('v-text-field',{staticClass:"ff-regular",attrs:{"value":_vm.building,"rules":[
            function (v) {
              return !!v || _vm.$t('This field is required');
            } ],"dense":"","outlined":"","placeholder":_vm.$t('Bulding Number')},model:{value:(_vm.building),callback:function ($$v) {_vm.building=$$v},expression:"building"}})],1),_c('div',[_c('label',{staticClass:"hide-extra ff-regular",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Floor Number')))]),_c('v-text-field',{staticClass:"ff-regular",attrs:{"value":_vm.floor,"rules":[
            function (v) {
              return !!v || _vm.$t('This field is required');
            } ],"dense":"","outlined":"","placeholder":_vm.$t('Floor Number')},model:{value:(_vm.floor),callback:function ($$v) {_vm.floor=$$v},expression:"floor"}})],1)]),_c('div',{staticClass:"building-details d-flex justify-space-between mb-1"},[_c('div',[_c('label',{staticClass:"hide-extra ff-regular",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Apartment Number')))]),_c('v-text-field',{staticClass:"ff-regular",attrs:{"value":_vm.apartment,"rules":[
            function (v) {
              return !!v || _vm.$t('This field is required');
            } ],"dense":"","outlined":"","placeholder":_vm.$t('Apartment Number')},model:{value:(_vm.apartment),callback:function ($$v) {_vm.apartment=$$v},expression:"apartment"}})],1),_c('div',[_c('label',{staticClass:"hide-extra ff-regular"},[_vm._v(_vm._s(_vm.$t('Address Type')))]),_c('v-select',{staticClass:"ff-regular add-address-select",attrs:{"menu-props":_vm.menuClass,"value":_vm.type,"items":_vm.items,"item-text":"text","item-value":"id","placeholder":_vm.$t('Choose'),"dense":"","outlined":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"ff-regular"},[_vm._v(_vm._s(_vm.$t('Special Sign')))]),_c('div',{staticClass:"mt-2"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.notes),expression:"notes"}],staticClass:"mod-textarea ff-regular",attrs:{"rows":"3","placeholder":_vm.$t('Special Sign')},domProps:{"value":(_vm.notes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.notes=$event.target.value}}}),_c('span',[_vm._v(_vm._s(_vm.$t('address_disclaimer')))])])])]),_c('div',{staticClass:"btn-container d-flex justify-center"},[_c('v-btn',{attrs:{"id":"checkout","depressed":"","dark":"","color":_vm.mainColor},on:{"click":_vm.confirmAddress}},[_vm._v(_vm._s(_vm.$t('save_address'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }