<template>
  <v-app>
    <router-view />
    <loader :loader="$store.state.core.loader" />
    <error-popup></error-popup>
    <v-snackbar
      v-model="$store.state.core.snackbar.status"
      :color="$store.state.core.snackbar.color"
      :timeout="1000"
    >
      <div v-html="$store.state.core.snackbar.text" />
      <v-btn dark @click="$store.state.core.snackbar.status = false">
        Close
      </v-btn>
    </v-snackbar>
    <dynamic-style />
  </v-app>
</template>

<script>
import dynamicStyle from "@/components/style";
import Loader from "@/common/loader";
import errorPopup from "./errors/components/errorPopup.vue";

export default {
  name: "App",
  components: { Loader, dynamicStyle, errorPopup },
  data: () => ({
    //
    allowredRoutes: [
      "/order",
      "/order/ext",
      "/order/summery",
      "/order/order-confirmed",
    ],
  }),
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
@font-face {
  font-family: Poppins-Medium;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins-Bold;
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: Poppins-Light;
  src: url("./assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins-Regular;
  src: url("./assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins-Thin;
  src: url("./assets/fonts/Poppins-Thin.ttf");
}
@font-face {
  font-family: Poppins-Italic;
  src: url("./assets/fonts/Poppins-Italic.ttf");
}
/* @font-face {
  font-family: Roboto-Bold;
  src: url("./assets/fonts/roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: Roboto-Medium;
  src: url("./assets/fonts/roboto/Roboto-Medium.ttf");
}
@font-face {
  font-family: Roboto-Regular;
  src: url("./assets/fonts/roboto/Roboto-Regular.ttf");
} */
@font-face {
  font-family: Tag-Light;
  src: url("./assets/fonts/tajawal/Tajawal-Light.ttf");
}
@font-face {
  font-family: Tag-Regular;
  src: url("./assets/fonts/tajawal/Tajawal-Regular.ttf");
}
@font-face {
  font-family: Tag-Bold;
  src: url("./assets/fonts/tajawal/Tajawal-Bold.ttf");
}
</style>
