<template>
  <div class="pag-loader" v-if="loader">
    <v-progress-circular
      :size="65"
      :width="6"
      color="#afafaf"
      indeterminate
      id="loading"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: ["loader"],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.pag-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000000;
  opacity: 0.5;
  background: rgba(256, 256, 256, 0.99);
  .v-progress-circular {
    height: 70px;
    width: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
