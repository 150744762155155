<template>
  <div>
    <div class="step-actions">
      <v-icon
        @click="step--"
        color="black"
        v-show="step != 1"
        v-if="$i18n.locale === 'en'"
        >mdi-arrow-left</v-icon
      >
      <v-icon @click="step--" color="black" v-show="step != 1" v-else
        >mdi-arrow-right</v-icon
      >
      <v-spacer></v-spacer>
      <v-icon @click="cancelBooking" color="black">mdi-close</v-icon>
    </div>

    <div class="booking-steps" :style="clientStyle">
      <div class="logo d-flex justify-center">
        <img :src="$store.state.core.clientStyle.logo" alt="" />
      </div>

      <link-expired
        v-if="expired"
        :WARedirectNo="cardData.number"
        :FBRedirectPageNo="cardData.page_id"
        :channel="cardData.channel"
        :TWRedirectPageNo="cardData.twitter_id"
      />

      <v-stepper
        v-model="step"
        alt-labels
        elevation="0"
        v-if="cardData.step1"
        v-ripple="false"
      >
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1" :editable="step > 1">
            <div class="step-img">
              <img :src="cardData.step1.icon" width="20" alt="" />
            </div>
            <p class="mb-0 text-center d-block">{{ cardData.step1.name }}</p>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2" :editable="step > 2">
            <div class="step-img">
              <img :src="cardData.step2.icon" width="20" alt="" />
            </div>
            <p class="mb-0 text-center d-block">{{ cardData.step2.name }}</p>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            <div class="step-img">
              <img :src="cardData.step3.icon" width="20" alt="" />
            </div>
            <p class="mb-0 text-center">{{ cardData.step3.name }}</p>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <step1 @nextStep="step++" @cancel="cancelBooking" />
          </v-stepper-content>

          <v-stepper-content step="2">
            <step2 @nextStep="step++" v-if="step > 1" @cancel="cancelBooking" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <step3
              v-if="step > 2"
              @submitBooking="submitBooking"
              @cancel="cancelBooking"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import step1 from "./step1/step1";
import step2 from "./step2/step2";
import step3 from "./step3/step3";
import LinkExpired from "../../errors/link-expired";
import Common from "@/utils/common";

export default {
  name: "BookingSteps",
  components: {
    step1,
    step2,
    step3,
    LinkExpired,
  },
  data() {
    return {
      step: 1,
      expired: false,
      cardData: {},
    };
  },
  async mounted() {
    await this.$store.dispatch("setLoader", true);
    await this.setStyle();
    await this.getCardData();
    await this.$store.dispatch("setLoader", false);
  },
  methods: {
    async getCardData() {
      try {
        const res = await this.$http_gateway.get(
          `extended-message?user_id=${this.$route.query.user_id}&bot_id=${this.$route.query.bot_id}&slug=book-appointment`
        );
        if (res.data.not_found) this.expired = true;
        this.cardData = res.data;
        await this.$store.dispatch("setStepsData", this.cardData);
        await this.$store.dispatch("setLang", this.cardData.lang);
        this.$vuetify.rtl = this.cardData.lang === "ar";
        this.$i18n.locale = this.cardData.lang;
        await this.setStyle();
      } catch (e) {
        console.log(e);
      }
    },
    async setStyle() {
      await this.$store.dispatch("setClientStyle", {
        botId: this.$route.query.bot_id,
        lang: this.$i18n.locale,
      });
      this.$vuetify.theme.themes.light.primary = this.$store.state.core.clientStyle.mainColor;
      this.$vuetify.theme.themes.light.secondary = this.$store.state.core.clientStyle.introButtonsColor;
      document.documentElement.style.setProperty(
        "--main-color",
        this.$store.state.core.clientStyle.mainColor
      );
    },
    async submitBooking() {
      await this.$store.dispatch("setLoader", true);
      try {
        const rest = await this.$http_gateway.post(`extended-message`, {
          channel: this.$store.state.bookingSteps.stepsData.channel,
          user_id: this.$route.query.user_id,
          bot_id: this.$route.query.bot_id,
          text: JSON.stringify(
            this.$store.state.bookingSteps.selectedStepsData
          ),
        });
        Common.handleRedirection(this.cardData);
      } catch (e) {
        console.log(e);
      } finally {
        await this.$store.dispatch("setLoader", false);
      }
    },
    async cancelBooking() {
      await this.$store.dispatch("setLoader", true);
      try {
        const res = await this.$http_gateway.post(`/cancel_all_dialogs`, {
          channel: this.$store.state.bookingSteps.stepsData.channel,
          userId: this.$route.query.user_id,
          botId: this.$route.query.bot_id,
          cancelRedirectBlock: this.$store.state.bookingSteps.stepsData
            .cancelRedirectBlock,
          slug: "book-appointment",
        });
        Common.handleRedirection(this.cardData);
      } catch (e) {
      } finally {
        await this.$store.dispatch("setLoader", false);
      }
    },
  },

  computed: {
    clientStyle() {
      return {
        "--main-color": this.$store.state.core.clientStyle.mainColor,
        "--secondary-color": this.$store.state.core.clientStyle
          .introButtonsColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.v-stepper__header {
  height: 100px;
  margin-top: -16px;
}

::v-deep .v-stepper__header .v-ripple__container {
  display: none !important;
}

.booking-steps {
  position: relative;
  .logo {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }
}

@media (min-width: 1400px) {
  .booking-steps {
    .logo {
      position: absolute;
      z-index: 1000;
      max-width: 300px;
      top: 10px;
      left: 75px;
    }
  }
  .v-application--is-rtl {
    .booking-steps {
      .logo {
        right: 750px;
        left: auto;
      }
    }
  }
}

.v-application--is-rtl {
  .booking-steps {
    .logo {
      right: 75px;
      left: auto;
    }
  }
}

.step-actions {
  padding: 0px 35px 0px 35px;
  position: absolute;
  z-index: 10;
  justify-content: space-between;
  display: flex;
  top: 20px;
  width: 100%;
}
</style>
