<template>
  <div
    :class="
      $i18n.locale +
      ' order-confirmed-container d-flex justify-center align-center'
    "
  >
    <div class="order-confrimed text-center">
      <img src="../../assets/img/ic_done.svg" width="110" alt="" />
      <div class="confirmed mt-6 ff-bold">
        {{ $t('Your order is confirmed') }}
      </div>
      <div class="delivery mt-2">
        {{ $t('Order will be delivered within') }} {{ order.delivery_at }}
        {{ $t('minutes') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'locationTestView',
  data: () => ({
    loading: false,
  }),
  mounted() {
    setTimeout(() => {
      this.redirect();
    }, 2500);
  },
  methods: {
    redirect() {
      if (this.channel === 'whatsapp')
        window.location.replace(
          `https://wa.me/${this.channelData.WARedirectNo}`
        );
      else if (this.channel === 'facebook')
        window.location.replace(
          `http://m.me/${this.channelData.FBRedirectPageNo}`
        );
      else if (this.channel == 'twitter')
        window.location.replace(
          `https://twitter.com/messages/${this.channelData.TWRedirectPageNo}`
        );
    },
  },
  computed: {
    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
    channel: {
      get() {
        return this.$store.state.order_ext.channel;
      },
      set(value) {
        this.$store.commit('updateChannel', value);
      },
    },
    channelData: {
      get() {
        return this.$store.state.order_ext.channelData;
      },
      set(value) {
        this.$store.commit('updateChannelData', value);
      },
    },
  },
};
</script>

<style lang="scss">
.order-confirmed-container {
  height: 100vh;
  background: rgb(41, 41, 41);
  background-image: url('../../assets/img/DottedBG.svg');
  background-size: cover;
  .order-confrimed {
    width: fit-content;
    color: white;
    .confirmed {
      font-size: 24px;
    }
    .delivery {
      font-size: 12px;
      font-style: italic;
      opacity: 0.7;
    }
  }
}
</style>
