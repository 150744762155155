<template>
  <div>
    <v-container>
      <h3 class="text-secondary mb-4 text-uppercase font-weight-bold">
        {{ $t("NationalId") }}
      </h3>

      <loader :loader="loading" />
      <div>
        <p class="font-weight-bold">{{ $t("FrontSide") }}</p>
        <ej-2-file-uploader
          types=".jpg, .png, .jpeg"
          @onImageUpload="updateFrontSideUrl"
          :uploadEnabled="input.frontSide.uploadEnabled"
        ></ej-2-file-uploader>
      </div>

      <v-divider class="inner-divider"></v-divider>

      <div v-if="backsideEnabled">
        <p class="font-weight-bold">{{ $t("BackSide") }}</p>
        <ej-2-file-uploader
          types=".jpg, .png, .jpeg"
          @onImageUpload="updateBackSideUrl"
          :uploadEnabled="input.backSide.uploadEnabled"
        ></ej-2-file-uploader>
      </div>
    </v-container>

    <div class="step-nav">
      <div class="d-flex align-center">
        <v-btn
          outlined
          color="secondary"
          class="mx-5"
          elevation="0"
          :disabled="cancelBtnDisabled"
          @click="$emit('cancel')"
        >
          {{ $t("Cancel") }}
        </v-btn>

        <v-btn
          color="primary"
          class="mx-5"
          :disabled="!nextStepEnabled"
          @click="handleNextStepButton"
          elevation="0"
        >
          {{ nextStepBtnTitle }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/dynamicAPI";

// import FileUploaderDropzone from "../../../components/file-upload/file-uploader-dropzone.vue";
import Loader from "../../../common/loader.vue";
import Ej2FileUploader from "../../../components/file-upload/ej2-file-uploader.vue";

export default {
  name: "step1",
  components: {
    // FileUploaderDropzone,
    Ej2FileUploader,
    Loader,
  },
  data() {
    return {
      step: 1,
      backsideEnabled: false,
      canCancel: true,
      loading: false,
      vlens: {
        front: null,
        back: null,
      },
      input: {
        frontSide: {
          url: "",
          base64: "",
          uploadEnabled: true,
        },
        backSide: {
          url: "",
          base64: "",
          uploadEnabled: true,
        },
      },
    };
  },
  mounted() {},
  methods: {
    nextStep() {
      this.$emit("nextStep", {
        id: 2,
        name: "car-license",
      });
    },
    submit() {
      this.$emit("submitData");
    },
    updateFrontSideUrl(e) {
      this.input.frontSide.url = e[0];
      this.input.frontSide.base64 = e[1];
    },
    updateBackSideUrl(e) {
      this.input.backSide.url = e[0];
      this.input.backSide.base64 = e[1];
    },
    async submitFrontSide(val) {
      try {
        await this.$store.dispatch("setLoader", true);
        const nBase64 = val.replace(
          /data:|image\/png;|image\/jpeg;|image\/jpg;|base64,/g,
          ""
        );

        let transaction_id =
          this.$store.state.ocrSteps.vlens.transactionId || null;

        const response = await API.postAPI(
          {
            url: `${process.env.VUE_APP_API_GATEWAY}/extended-message/ocr`,
          },
          {
            "Content-Type": "application/json",
          },
          {
            type: "national-id",
            side: "front",
            image: nBase64,
            transaction_id,
          }
        );

        await this.$store.dispatch("setLoader", false);
        const responseData = response.data;

        const { services, data } = responseData;
        if (services.Validations.validation_errors.length) {
          let validationError = services.Validations.validation_errors[0];
          let errorCode = validationError.errors[0].code;

          this.$store.dispatch(
            "setErrorMessage",
            this.$t(`VlensError${errorCode}`)
          );
        } else {
          if (!transaction_id) {
            this.$store.dispatch("setVlensTransactionId", data.transaction_id);
          }
          this.vlens.front = data;
          this.input.frontSide.uploadEnabled = false;
          this.backsideEnabled = true;
        }
      } catch (e) {
        await this.$store.dispatch("setLoader", false);
        console.log(e);
        this.$store.dispatch("setErrorMessage", e.message);
      }
    },
    async submitBackSide(val) {
      try {
        await this.$store.dispatch("setLoader", true);
        const nBase64 = val.replace(
          /data:|image\/png;|image\/jpeg;|image\/jpg;|base64,/g,
          ""
        );
        let transaction_id = this.$store.state.ocrSteps.vlens.transactionId;

        const response = await API.postAPI(
          {
            url: `${process.env.VUE_APP_API_GATEWAY}/extended-message/ocr`,
          },
          {
            "Content-Type": "application/json",
          },
          {
            type: "national-id",
            side: "back",
            image: nBase64,
            transaction_id,
          }
        );
        await this.$store.dispatch("setLoader", false);
        const responseData = response.data;

        const { services, data } = responseData;

        if (services.Validations.validation_errors.length) {
          let validationError = services.Validations.validation_errors[0];
          let errorCode = validationError.errors[0].code;

          this.$store.dispatch(
            "setErrorMessage",
            this.$t(`VlensError${errorCode}`)
          );
        } else {
          this.vlens.back = data;
          this.input.backSide.uploadEnabled = false;

          // Set NationalId data in store
          this.$store.dispatch("setNationalIdData", {
            ...this.vlens.front,
            ...this.vlens.back,
          });
        }
      } catch (e) {
        await this.$store.dispatch("setLoader", false);
        console.log(e);
        this.$store.dispatch("setErrorMessage", e.message);
      }
    },
    handleNextStepButton() {
      let cardData = this.$store.state.ocrSteps.stepsData;

      let steps = cardData.steps;

      if (steps) {
        let stepsLength = steps.length;

        if (this.step >= stepsLength) {
          return this.submit();
        } else {
          return this.nextStep();
        }
      }
    },
  },
  computed: {
    fileTypes() {
      return "image/png, image/jpeg, image/jpg";
    },
    backSideValidationMessage() {
      if (!this.input.frontSide.base64) {
        return this.$t("FrontSideNotUploaded");
      }

      return this.$t("FileRequired");
    },
    isClickable() {
      return this.backsideEnabled;
    },
    nextStepBtnTitle() {
      let cardData = this.$store.state.ocrSteps.stepsData;

      let steps = cardData.steps;

      if (steps) {
        let stepsLength = steps.length;

        if (this.step >= stepsLength) {
          return this.$t("CompleteSteps");
        } else {
          return this.$t("NextStep");
        }
      }
      return this.$t("NextStep");
    },
    cancelBtnDisabled() {
      let cardData = this.$store.state.ocrSteps.stepsData;

      let steps = cardData.steps;

      if (steps) {
        let stepsLength = steps.length;

        if (this.step >= stepsLength) {
          if (this.vlens.front && this.vlens.back) {
            return true;
          }
        }
      }
      return false;
    },
    nextStepEnabled() {
      if (this.vlens.front && this.vlens.back) {
        return true;
      }

      return false;
    },
  },
  watch: {
    "input.frontSide.base64": {
      handler: async function (val) {
        // console.log(val);
        if (val && val.length) {
          await this.submitFrontSide(val);
        } else {
          this.input.backSide = {};
          this.backsideEnabled = false;
        }
      },
    },
    "input.backSide.base64": {
      handler: async function (val) {
        if (val && val.length) {
          await this.submitBackSide(val);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 0px 12px 12px 12px;
  margin-right: auto;
  margin-left: auto;
}
</style>
