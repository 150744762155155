import Vue from "vue";
import VueRouter from "vue-router";
import * as VueGoogleMaps from "vue2-google-maps";
import App from "./App.vue";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import { routes } from "./routes";
import store from "./store/index";

import { registerLicense } from "@syncfusion/ej2-base";
import "./assets/sass/main.scss";
import "./plugins/axios";
Vue.use(VueRouter);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBNK-iGEM5uYffXvIZ98l676iOhyxFLYRE",
    libraries: "places",
  },
});

import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false;

const router = new VueRouter({ routes, mode: "history" });

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});
// Registering Syncfusion license key for ej2 components
registerLicense(process.env.VUE_APP_EJ2_LICENSE_KEY);
new Vue({
  vuetify,
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
