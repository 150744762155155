<template>
  <v-dialog :value="errorMsg" width="600">
    <v-card class="text-center pa-5">
      <h2>{{ errorMessageText }}</h2>
      <v-btn
        rounded
        class="mt-3"
        color="primary"
        @click="$store.dispatch('setErrorMessage', false)"
        >{{ $t("Confirm") }}</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "errorPopup",
  computed: {
    errorMsg() {
      return !!this.$store.state.core.errorMsg;
    },
    errorMessageText() {
      return typeof this.$store.state.core.errorMsg === "string"
        ? this.$store.state.core.errorMsg
        : "Something went wrong please try again later";
    },
  },
};
</script>

<style scoped></style>
