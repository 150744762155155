<template>
  <div class="text-center order-listing-mob">
    <v-bottom-sheet v-model="sheet" inset>
      <template v-slot:activator="{ on, attrs }">
        <div class="basket-btn-container">
          <div class="basket-btn" @click="closeOverlayAndUpdate">
            <div class="count-and-price">
              <div class="count">{{ convertToArabicNumbers(getOrdersCount()) }}</div>
              <div class="price">
                <div>
                  {{
                    convertToArabicNumbers((basket.length > 0
                      ? order.cost && order.cost.sub_total
                      : 0))
                  }} {{
                    convertToArabicNumbers((order.cost && order.cost.currency) ||
                    overlayMealDetails.data.currency)
                  }}
                </div>
                <div>

                </div>
              </div>
            </div>
            <div class="text">
              {{ $t('view basket') }}
            </div>
          </div>
        </div>
      </template>
      <v-sheet class="text-center order-listing-sheet">
        <div
          class="close-btn-container"
          :style="{ direction: $i18n.locale == 'ar' ? 'rtl' : 'ltr' }">
          <v-icon :color="mainColor" @click="sheet = !sheet">mdi-close</v-icon>
        </div>
        <div class="order-listing-mob">
          <div v-if="!basket.length">
            <empty-cart />
          </div>
          <div v-else>
            <cart-with-orders />
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import EmptyCart from './components/empty-cart.vue';
import CartWithOrders from './components/cart-with-orders.vue';
export default {
  components: {
    EmptyCart,
    CartWithOrders,
  },
  data: () => ({
    sheet: false,
  }),

  methods: {
    convertToArabicNumbers: function (data) {
      if (this.$i18n.locale !== 'ar') {
        return data;
      } else {
        const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
        return String(data).replace(/[0123456789]/g, (d) => {
          return arabicNumbers[d]
        });
      }
    },
    getSelectedItemsTotalPrice() {
      let arr = this.overlayMealDetails.data.mealsAttributeSets.map((e) =>
        e.selectedPrice ? e.selectedPrice : 0
      );

      let checkboxSelectedItems = [];

      for (
        let index = 0;
        index < this.overlayMealDetails.data.mealsAttributeSets.length;
        index++
      ) {
        const element = this.overlayMealDetails.data.mealsAttributeSets[index];
        if (element.selected && typeof element.selected != 'string') {
          element.selected.forEach((e) => {
            element.mealsAttributeSetOption.data.forEach((el) => {
              if (e == el.id) checkboxSelectedItems.push(el);
            });
          });
        }
      }

      let checkboxPrices = checkboxSelectedItems.length
        ? checkboxSelectedItems.map((e) => e.price)
        : [];
      return (
        (arr.length
          ? arr.reduce((acc, no) => acc + no) *
            this.overlayMealDetails.data.quantity
          : 0) +
        (checkboxPrices.length
          ? checkboxPrices.reduce((acc, no) => acc + no) *
            this.overlayMealDetails.data.quantity
          : 0)
      );
    },
    getOrdersCount() {
      var count = 0;
      if (this.basket.length) {
        for (let j = 0; j < this.basket.length; ++j) {
          count += this.basket[j].quantity;
        }
      }
      return count;
    },
    closeOverlayAndUpdate() {
      //console.log("Basket Clicked")
      this.sheet = true;
      if (this.overlayMealDetails.dialog === true) {
        this.overlayMealDetails = {
          dialog: false,
          data: { ...this.overlayMealDetails.data },
        };
      }
    },
    getOptions() {
      let idsForRadio = [];
      let idsForCheckbox = [];

      for (
        let index = 0;
        index < this.overlayMealDetails.data.mealsAttributeSets.length;
        index++
      ) {
        const element = this.overlayMealDetails.data.mealsAttributeSets[index];

        if (element.selected && typeof element.selected != 'string') {
          element.selected.forEach((e) => {
            idsForCheckbox.push({
              attribute_set_option_id: e,
            });
          });
        }
        if (element.selected && typeof element.selected == 'string') {
          idsForRadio.push({
            attribute_set_option_id: element.selected,
          });
        }
      }
      return [...idsForRadio, ...idsForCheckbox];
    },
  },
  computed: {
    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit('updatePaymentDetails', value);
      },
    },
    notes: {
      get() {
        return this.$store.state.order_ext.notes;
      },
      set(value) {
        this.$store.commit('updateNotes', value);
      },
    },
    overlay: {
      get: function () {
        return this.$store.state.order_ext.overlay;
      },
      set: function (value) {
        this.$store.commit('updateOverlay', value);
      },
    },
    calculating: {
      get() {
        return this.$store.state.order_ext.calculating;
      },
      set(value) {
        this.$store.commit('updateCalculating', value);
      },
    },
    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit('updateOrderId', value);
      },
    },
    address: {
      get() {
        return this.$store.state.order_map.address;
      },
      set(value) {
        this.$store.dispatch('setAddress', value);
      },
    },
    overlayMealDetails: {
      get() {
        return this.$store.state.order_ext.overlayMealDetails;
      },
      set(value) {
        this.$store.commit('updateOverlayMealDetails', value);
      },
    },
    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit('updateBasket', value);
      },
    },
    order: {
      get: function () {
        return this.$store.state.order_ext.order;
      },
      set: function (value) {
        this.$store.commit('updateOrder', value);
      },
    },
    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/order-listing-mob.scss';
</style>
